import logo from "./logo.svg";
import "./App.css";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, bscTestnet } from "viem/chains";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import { useEffect } from "react";
import Aos from "aos";
import { Staking } from "./components/Context/StakingApp";

function App() {
  // please use your project is ------walletconnect.com-----------
  const projectId = "b2294da0e3124efc6f699d54580908ce";


  // --------------here is the basic metadata of the Your website --------------------
  // change accordingly
  const metadata = {
    name: "BEP20",
    description:
      "Welcome to BEP20, It is future of innovation and finance",
      // insure domin is correctly.
    url: "www.BEP20.co",
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const chains = [bsc, bscTestnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });
  return (
    <WagmiConfig config={wagmiConfig}>
      <Staking>
        <Home />
      </Staking>
      <ToastContainer />
    </WagmiConfig>
  );
}

export default App;
