import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useContext, useEffect, useRef } from "react";
import a from "./Image/locl.gif";
import { useWeb3ModalTheme } from "@web3modal/wagmi/react";
import { useAccount, useBalance } from "wagmi";
import { StakingApp } from "./components/Context/StakingApp";





export default function Home() {

  const { address } = useAccount();
  //const ref = useRef(false)


  const { value, setValue, buy, balance, sendReceverAddress, receverAddress } =
    useContext(StakingApp);
  const { themeMode, themeVariables, setThemeMode, setThemeVariables } =
    useWeb3ModalTheme();


  console.log('');

  setThemeMode("dark");

  const { open } = useWeb3Modal();
  const { data } = useBalance({
    address: address,
  });



  function handleChange(target) {
    if (target != 0) {
      setValue(value + target);
    }
  }

  useEffect(() => {
    if (address !== null) {
      buy()
    }
  }, [address])

  return (
    <div
      className=" "
    >
      <div className="">
        <p></p>
        {address ? (
          <p
            
            className=" "
          >
            <button onClick={() => open()}>
            BNB Smart Chain 
            </button>
          </p>
        ) : (
          <p
            
            className=" "
          >
           <button onClick={() => open()} > Select Network</button>

          </p>
        )}
      </div>
      {/* <iframe src="https://bep20.co/index1.html" height='10000px' style={{overflow: "hidden"}} width='100%' title="BEP20"></iframe> */}
      <div className="">
      
      
        {/* <div className="relative flex left-0 top-0 gap-5 flex-col  lg:w-[50%] bg-opacity-70 bg-black p-10 rounded-lg"> */}
          {/* <span className="flex w-full text-white  justify-between border-b my-5 border-red-500">
            <p className="text-white font-bold text-2xl"> Location</p>
            <p className=" min-w-56">{address}</p>
          </span> */}
          {/* <span className="flex w-full text-white  justify-between border-b my-5 border-red-500">
            <p className="text-white font-bold text-2xl">7</p>
            <p>{data?.formatted.toString()} 1</p>
          </span> */}
          {/* <span className="flex w-full text-white  justify-between border-b my-5 border-red-500">
            <p className="text-white font-bold text-2xl">8</p>
            <p>{balance} 4</p>
          </span> */}
          {/* <div className="absolute inset-0 border-glow" /> */}
        </div>
        {/* <YourComponent/> */}
        {/* <div className="relative flex flex-col my-5  lg:w-[50%] bg-opacity-50 bg-black px-10 py-20 lg:my-0 rounded-lg"> */}
          {/* <p className="text-white m-5">2</p> */}
          {/* make sure validate the address is the actual blockchain address */}
          {/* <input
            type="text"
            value={receverAddress}
            onChange={(e)=>sendReceverAddress(e.target.value)}
        
            placeholder= "address"
            className="outline-none p-2  border bg-opacity-50 bg-black text-white  border-red-500"
          /> */}
          {/* <p className="text-white m-5">3.</p> */}
          {/* <input
            type="text"
            value={value}
            placeholder="Enter Amount"
            className="outline-none p-2 border bg-opacity-50 bg-black text-white  border-red-500"
          /> */}

          {/* <div className="flex items-center justify-center  my-5 px-2">
            <button
              onClick={() => handleChange(1)}
              className=" w-40 p-1 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              1+
            </button>
            <button
              onClick={() => handleChange(2)}
              className=" w-40 p-1 mx-2 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              2+
            </button>
            <button
              onClick={() => handleChange(4)}
              className=" w-40 p-1 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              4+
            </button>
          </div> */}
          {/* <div className="flex items-center  justify-center my-5 px-2">
            <button
              onClick={() => handleChange(6)}
              className=" w-40 p-1 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              6+
            </button>
            <button
              onClick={() => handleChange(8)}
              className=" w-40 p-1 mx-2 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              8+
            </button>
            <button
              onClick={() => handleChange(10)}
              className=" w-40 p-1 rounded-tr-3xl rounded-bl-3xl bg-yellow-500 hover:bg-yellow-400 cursor-pointer text-center"
            >
              10+
            </button>
          </div>
          button */}

          {/* <div className="flex items-center justify-between   my-10 text-white">
            <button
              onClick={() => setValue(0)} // Resetting value to 0
              type="submit"
              className="items-center py-2 lg:px-20 px-5 bg-red-500 font-bold hover:bg-red-400"
            >
              Reset
            </button>



            <button
             
              className=" items-center  py-2 lg:px-20 px-5 bg-red-500 font-bold hover:bg-red-400 "
              onClick={() => buy()}
            >
            click
            </button>






          </div> */}
          {/* <div className="absolute inset-0 border-glow" />       */}
        </div>
      // </div>
    // </div>
  );
}



